<!--岗位选择-->
<template>
  <div class="job-list">
    <van-sticky>
      <div class="selectJob" v-if="jobPlaceList">
        <div class="areaTab">
          <span @click="selectJobPlaceClick(item)" :class="{active : placeName === item}" v-for="(item, index) of jobPlaceList" :key="index">{{item}}</span>
        </div>
        <div class="seach-box">
          <div class="type-select" @click="typeShow = true">
            <span>{{selectedType.name}}</span>
            <em class="arrow"></em>
          </div>
          <!-- <input type="text"  placeholder="请输入"/> -->
          <van-field v-model="jobvalue" clearable placeholder="请输入" />
          <button class="confirm" @click="initJobList">搜索</button>
        </div>
        <van-action-sheet v-model="typeShow" :actions="actions" @select="onTypeSelect" />
      </div>
    </van-sticky>
    <van-list
      v-model="loading"
      @load="getJobList"
      :finished="finished"
    >
      <!-- 内容 -->
      <div class="jobList" v-for="(item, index) of jobList" :key="index">
        <div class="jobInfo" @click="selectItem(item)">
          <div class="info-content">
            <div class="infoList">
              <span>部门名称：</span>
              <p>{{item.departmentName}}</p>
            </div>
            <div class="infoList">
              <span>单位名称：</span>
              <p>{{item.employer}}</p>
            </div>
            <div class="infoList">
              <span>职位名称：</span>
              <p>{{item.positionName}}</p>
            </div>
            <div class="number">
              <span>招聘人数：</span>
              <p>{{item.numberCandidates}}</p>
              <span class="job-number">职位代码：</span>
              <p>{{item.jobCode}}</p>
            </div>
          </div>
          <!-- <input type="radio" value="1"> -->
          <span class="select-btn">选择</span>
        </div>
        <div class="jobDetail">
          <p @click="jobDetail(item)">查看岗位详情</p>
        </div>
      </div>
    </van-list>
    <van-empty style="background:#fff;" v-if="jobList.length === 0" description="暂无数据" />
    <!-- <van-overlay :show="showJObDetail" :lock-scroll="false">
      <div class="wrapper" @click="showJObDetail = false"  @touchmove.prevent>
        <JobDetail  @onClose="infoOnClose" :detail="selectJob" />
      </div>
    </van-overlay> -->
    <div class="wrapper" v-if="showJObDetail" @click="showJObDetail = false">
      <JobDetail  @onClose="infoOnClose" :detail="selectJob" />
    </div>
  </div>
</template>
<script>
import { getJobPlaceName, getJobList, checkBeforeCreate, checkFully } from '@/api/api'
import JobDetail from '@/components/courseGroup/signup/JobDetail'
import { JOB_TYPE_QUERY } from '@/config/config'
export default {
  name:'selectJob',
  data() {
    return {
      loading: false,
      finished: false,
      jobPlaceList: null,
      placeName: '全部',
      orderInfo: null,
      jobkey: '',
      jobvalue: '',
      page: 1,
      limit: 5,
      jobList: [],
      showJObDetail: false,
      selectJob: null,
      typeShow: false,
      actions: JOB_TYPE_QUERY,
      selectedType: {},
    }
  },
  components: {
    JobDetail
  },
  mounted() {
    let orderInfo = this.$store.state.orderInfo || null
    if(orderInfo) {
      this.orderInfo = orderInfo
      this.getJobPlaceName()
    }
    this.selectedType = JOB_TYPE_QUERY[0]
    this.jobkey = JOB_TYPE_QUERY[0].key
  },
  methods:{
    onTypeSelect(val) {
      this.selectedType = val
      this.typeShow = false
      this.jobkey = val.key
    },
    // 地区选择
    selectJobPlaceClick(item) {
      this.placeName = item
      this.loading = true
      this.initJobList()
    },
    selectItem(item) {
      this.checkFully(item)
     
    },
    initJobList() {
      
      this.page = 1
      this.getJobList(true)
      // this.jobList = []
    },
    async getJobList(clear = false) {
      //console.log('clear', clear)
      let params = {
        type: this.jobkey,
        val: this.jobvalue,
        page: this.page,
        limit: this.limit,
        placeName: this.placeName,
      }
      let orderInfo = this.orderInfo

      if(orderInfo) {        
        params.jobListId = this.orderInfo.jobListId
      }
      if(this.placeName !== '全部') {
        params.placeName = this.placeName
      }else{
        params.placeName =''
      }
           
      let response =  await getJobList(params)
      
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      } else {
        
        if(response.data && response.data.length !== 0) {
          this.page += 1
          this.finished = false
          this.loading = false

        } else {
          this.finished = true
        }
        if(clear) {
          this.jobList = []
        }
        this.jobList.push(...response.data)
      }
      
    },
    jobDetail(item){
      this.selectJob = item
      this.showJObDetail = true
    },
    infoOnClose() {
      this.showJObDetail = false
      
    },
    async getJobPlaceName() {
      let params = {
        jobListId: this.orderInfo.jobListId
      }
      let response = await getJobPlaceName(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      this.jobPlaceList = response.data
      // this.getJobList()
    },
    async checkFully(val) {
      let params = {
        jobTableId: val.id
      }
      let response = await checkFully(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.orderInfo.loJboInfo = val
      this.$store.dispatch('setOrderInfo',Object.assign({}, this.$store.state.orderInfo, {loJboInfo: val}))
      // localStorage.setItem('orderInfo',JSON.stringify(this.orderInfo))
      this.$nextTick(() => {
        // window.history.go(-1)
        this.$router.go(-1)
      })
      
    },
    async checkBeforeCreate(val) {
      let courseGroupId = ''
      if(this.orderInfo.loCourseInfo) {
        courseGroupId = this.orderInfo.loCourseInfo.id
      }
      let params = {
        courseGroupId: courseGroupId
      }
      let response = await checkBeforeCreate(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$store.commit('order/setOrderInfo',{
        loJboInfo:val,
      })
      this.$router.push({path: `/course/signup`})
    },

  }
  
}
</script>
<style lang="less" scoped>
.job-list{
  background: #F3F3F3;
}
.selectJob{
  /* width:94%; */
  padding:0.1rem 3%;
  background:#fff;
  border-bottom: 1px solid #F3F3F3;
}
.areaTab{
  display: flex;
  padding:0.15rem 0;
  /* background: #fff000; */
  overflow-x: auto;
}
.areaTab span{
  display: block;
  font-size: 14px;
  color:#333;
  margin-right: 0.4rem;
  flex-grow: 0;
  flex-shrink: 0;
}
.areaTab .active{
  color:#FF3F47;
}
.seach-box{
  border:1px #DBDBDB solid;
  display: flex;
  height:0.8rem;
  margin: 0.3rem 0 0.1rem 0;
  border-radius: 4px;
  font-size: 13px;
  align-items: center;
}
.type-select{
  display: inline-flex;
}
.type-select .arrow{
  width: 0.3rem;
  height: 0.15rem;
  display: block;
  background: url("../../../assets/images/icon/icon-4.png") no-repeat;
  background-size: contain;
  margin-top:0.1rem;
  margin-left: 0.1rem;
}
.seach-box .van-cell{
  padding:7px 16px;
}
.seach-box select{
  line-height: 0.5rem;
  padding:0.15rem 0.15rem;
  border: none;
  outline: none;
}
.seach-box input{
  line-height: 0.5rem;
  padding:0.15rem 0.2rem;
  border: none;
  outline: none;
  flex-grow:2;
}
.seach-box .confirm{
  border: none;
  width:1.35rem;
  background: #FF3F47;
  border-radius: 4px;
  color:#fff;
  font-size: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 38px;
}
.jobList{
  /* width:94%; */
  padding:0.1rem 3%;
  background:#fff;
  margin-top:0.25rem;
}
// .info-content{
//   width: 85%;
// }
.jobInfo{
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding:0.1rem 0;
}
.info-content .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.7rem;
  text-align: left

}
.info-content .infoList span{
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
}
// .info-content .infoList p{
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   word-break: break-all;
// }
.info-content .number{
  color:#999;
  font-size: 14px;
  line-height: 0.7rem;
  text-align: left;
  display: flex;
}
.info-content .number .job-number{
  margin-left: 0.5rem;
}
.jobDetail{
  text-align: center;
  color:#FF3F47;
  font-size: 14px;
  height:0.6rem;
  line-height: 0.6rem;
  border-top: 1px solid #f2f2f5;
}
.wrapper {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.select-btn{
  display: block;
  color:#FF3F47;
  font-size: 14px;
  border: 1px solid #FF3F47;
  width: 1rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.06rem;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  
}
.type-select{
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 0.05rem;
}


</style>